// NOTE: use await along with settings.async = false with this wrapper to run a request synchronously
export default async function ajaxWithToken(settings, token = null) {
  // send Rails authenticity token
  const csrfTokenValue = $('meta[name="csrf-token"]').attr('content');
  if (settings.headers) {
    settings.headers['X-CSRF-Token'] = csrfTokenValue;
  } else {
    settings.headers = { 'X-CSRF-Token': csrfTokenValue };
  }

  // append URL auth token when present
  if (token) settings.url += `${settings.url.includes('?') ? '&' : '?'}token=${token}`;
  const isAsync = settings.async === 'true';

  // if we want synchronous behavior, use await
  return isAsync ? $.ajax(settings) : await $.ajax(settings);
}

window.ajaxWithToken = ajaxWithToken;
